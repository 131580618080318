// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "custom/application";
import "bootstrap";
import "bootstrap-select";
import "../../../vendor/assets/javascripts/sb-admin-pro";
import "../../../vendor/assets/javascripts/jquery.dataTables.min";
import "../../../vendor/assets/javascripts/dataTables.bootstrap4.min";
import "../../../vendor/assets/javascripts/Chart.min"

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
//Turbolinks.start()
ActiveStorage.start();