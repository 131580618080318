jQuery(function () {
  $('div.toast').toast('show');

  var table = $('#vouchersTable').DataTable({
    // https://github.com/feathericons/feather/issues/738
    // the below fixes a bug where feathericons are not visible on pages > 1 in datatables
    drawCallback: function (settings) {
      feather.replace();
    },
    order: [[3, 'asc']],
  });

  $('#usersTable').DataTable({
    // https://github.com/feathericons/feather/issues/738
    // the below fixes a bug where feathericons are not visible on pages > 1 in datatables
    drawCallback: function (settings) {
      feather.replace();
    },
  });

  $('#promoCodeTable').DataTable({
    // https://github.com/feathericons/feather/issues/738
    // the below fixes a bug where feathericons are not visible on pages > 1 in datatables
    drawCallback: function (settings) {
      feather.replace();
    },
  });

  $('#promoCodeUsersTable').DataTable({
    // https://github.com/feathericons/feather/issues/738
    // the below fixes a bug where feathericons are not visible on pages > 1 in datatables
    drawCallback: function (settings) {
      feather.replace();
    },
    order: [[6, 'desc']],
  });

  $('#buildingsTable').DataTable({
    // https://github.com/feathericons/feather/issues/738
    // the below fixes a bug where feathericons are not visible on pages > 1 in datatables
    drawCallback: function (settings) {
      feather.replace();
    },
  });

  $('#cardTab a').on('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
  });

  //Functions for next and previous buttons on the wizard
  $('.to-billing').click(function () {
    $('#cardTab a[href="#billing"]').tab('show');
  });
  $('.to-details').click(function () {
    $('#cardTab a[href="#details"]').tab('show');
  });
  $('.to-submit').click(function () {
    $('#cardTab a[href="#submit"]').tab('show');
  });

  //Show how many vouchers on review page
  $('#voucher_how_many').change(function () {
    $('#number-of-vouchers-review').text(
      $('#voucher_how_many option:selected')[0].value
    );
  });

  //Show buildings selected on review page
  $('#building-select')
    .selectpicker()
    .on('change', function () {
      $('#buildings-selected-review').empty();
      $('#building-select option:selected')
        .selectpicker()
        .each(function (e, v) {
          $('#buildings-selected-review').append(
            '<li class=justify-content-start>' + v.text + '</li>'
          );
        });
    });

  // Show use by date on review page
  $('.use-by-select').change(function () {
    $('#use-by-date-review').text(
      $('#voucher_use_by_date_2i option:selected')[0].text +
        ' ' +
        $('#voucher_use_by_date_3i option:selected')[0].value +
        ', ' +
        $('#voucher_use_by_date_1i option:selected')[0].value
    );
  });

  //Show plan selected on review page
  $('#voucher_valid_for_plan_tier').change(function () {
    $('#plan-valid-for-review').text(
      $('#voucher_valid_for_plan_tier option:selected')[0].text
    );
  });

  //Show PO Number on review page
  $('#voucher_po_number').on('input', function () {
    $('#po-number-review').text($('#voucher_po_number').val());
  });

  $('#building-select').selectpicker({
    liveSearch: true,
  });

  //Show voucher applies for on review page
  $('#voucher_months_applies_for').change(function () {
    $('#voucher-months-applies-for-review').text(
      $('#voucher_months_applies_for')[0].value
    );
  });

  //Filtering vouchers based on selected_status
  $('#filter_status').change(function () {
    console.log('drawing table');
    $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
      var status = data[3].split(' ');
      var selected_status = $('#filter_status').val();

      if (selected_status == 'all') {
        return true;
      }

      if (status.includes(selected_status)) {
        return true;
      } else {
        return false;
      }
    });
    table.draw();
    $.fn.dataTable.ext.search.pop();
  });
});
